.social-container {
  position: fixed;
  z-index: 999;
  right: 10px;
  bottom: 100px;
  top: auto !important;
  width: 62px;
  height: 140.63px;
  /* top: 757px; */
}

.social-phone {
  background: url("../images/icon-social-phone.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  width: 62px;
  height: 75.77px;
}

.social-mail {
  background: url("../images/icon-social-mail.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
  width: 61.78px;
  height: 60.86px;
}
