.home-btn-thamgia {
  flex-direction: row;
  justify-content: center;
  padding: 16px 24px;
  gap: 12px;
  /* position: absolute; */
  width: 100%;
  height: 60px;
  margin-top: 60vh;
  background: #c12528;
  border: 6px solid rgba(246, 222, 139, 0.6);
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.home-btn-thamgia-1,
.home-btn-thamgia-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;

  width: 100%;
  height: 56px;

  background: #df3b2a;
  /* border: 6px solid rgba(246, 222, 139, 0.6); */
  border-radius: 8px;
  margin-top: 20px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #f6de8b;
  text-decoration: none;
}

.home-btn-thamgia-1:hover {
  color: #f6de8b;
}

.home-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #ffffff;
  height: 18vh;
  overflow: scroll;
}
